/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Image } from '@swp/components'
import SiteHeader from '../../components/hr/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="pb--80 pt--80" name={"0il8bnfu5zat"}>
          
          <ColumnWrap className="column__flex js-anim  --anim3 --anim-s4 --center el--1 mt--60 pb--60" anim={"3"} animS={"4"} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 w--400" style={{"maxWidth":1110}} content={"722 Finch Street, Asbury Park, NJ 07712"}>
              </Title>

              <Subtitle className="subtitle-box mt--60" content={"510-851-4014"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--10" content={"info@vase-stranky.com"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"footer"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":1400}} columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box" content={"<span style='color: var(--color-dominant)'><a href=\"https://saywebpage.com\">Create your own website</a><br>722 Finch Street, Asbury Park, NJ 07712</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/cd8db1a198b540c896a32b45d514eef3.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":64}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--right" content={"<span style='color: var(--color-dominant)'>510-851-4014<br>info@vase-stranky.com</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}